<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
              <b-button
                variant="primary"
                v-b-modal.modal-no-animation
                class="ml-1"
              >
                <span class="text-nowrap">Import</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="lisFilter"
        :fields="tableColumns"
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <!-- <small class="text-muted">@{{ data.item.username }}</small> -->
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.user_type)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.user_type)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.user_type }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.isActive)}`"
            class="text-capitalize"
          >
            {{ data.item.isActive }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteUser(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >  
            <nav aria-label="Page navigation">
              <ul class="pagination">
                <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in meta.links" :key="link.id">
                  <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
                </li>
              </ul>
            </nav>        
            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination> -->
          </b-col>
        </b-row>
      </div> 
    </b-card>
    <b-modal
      id="modal-no-animation"
      content-class="shadow"
      :title="`Import Student Data`"
      centered
      size="sm"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Import Data"
            >
              <b-form-file
                @change="prepareFile"
                accept=".xlsx"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>

          <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="uploadData">
              Start Upload 
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BForm,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormFile, BFormGroup
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useUsersList from './useUsersList';
import userStoreModule from '../userStoreModule';
import UserListAddNew from './UserListAddNew.vue';

export default {
  components: {
    UserListAddNew,
    BForm,
    BFormInput,
    BFormGroup,
    BModal,
    BFormFile,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Student', value: 'STUDENT' },
      { label: 'Tutor', value: 'TUTOR' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  data() {
    return {
      students: [],
      meta: {},
      searchQuery: '',
      form: new FormData(),
      loading: false,
      url: '',
    }
  },

  computed: {
    lisFilter() {
      return this.students.filter(student => student.first_name.includes(this.searchQuery) || student.first_name.toLowerCase().includes(this.searchQuery) || student.last_name.includes(this.searchQuery))
    },
  },

  created() {
    this.url = this.$url+'/student'
    this.getStudents()
  },
  methods: {
    paginate(url) {
      this.url = url
      this.getStudents()
    }, 
    prepareFile(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      this.form.append('students', files[0])
    },     
    getStudents() {
      // this.$http.get(`${this.$url}/student`)
      this.$http.get(this.url)
        .then(response => {
          const students = response.data.data.data
          this.meta = response.data.data.meta
          this.students = students.map(student => ({
            id: student.id,
            first_name: student.first_name,
            last_name: student.last_name,
            phone_number: student.phone_number,
            email: student.email,
            avatar: student.avatar,
            code: student.code,
            date: student.created_at_formatted,
            status: student.isActive ? 'Active' : 'Inactive',
            role: 'STUDENT',
          }))
        })
    },
    deleteSelected(id) {
      this.$http.delete(`${this.$url}/student/${id}`)
        .then(response => {
          if (response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(()  => {
              window.location.reload()
            })
          }
        })
    },
    deleteUser(user) {
      this.$swal({
        title: `Delete ${user.first_name+' '+user.last_name}`,
        html: '<div class=" text-center mt-4"><h4 class="text-danger mb-3">Are you sure you want to delete this ?</h4></div>',
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary text-primary ml-1',
        },        
      }).then(result => {
        if (result.value) {
          this.deleteSelected(user.id)
        } 
      })
    },    
    uploadData() {
      this.loading = true
      this.$http.post(`${this.$url}/student/import-data`, this.form)
        .then(response => {
          if(response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })   
            .then(() => {
              window.location.reload()
            })         
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },    
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
